import React from "react";
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, change, untouch } from 'redux-form';
import { Link , browserHistory} from 'react-router';
import {toastr} from 'react-redux-toastr'
import moment from 'moment';
import _ from 'lodash';
import { FILES_UPLOAD_LOAD,
         FILES_UPLOAD_UNLOAD,
         FILES_TO_SEND,
         LOT_CREATED,
         FILE_SENT,
         LOT_CLOSED,
         ALL_LOTE_USER_LOAD,
         ALL_LOTE_USER_UNLOAD
       } from '../../actions/Constants';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import ReactTable from 'react-table';
import MaskDate from '../../components/Util/MaskDate';
import Title from '../../components/Layout/Title';
import SelectField from '../../components/Fields/SelectField';
import {  } from '../../actions/Constants';
import api from '../../api/apiUtil';

registerPlugin(FilePondPluginFileValidateType)

class FilesUpload extends React.Component {

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.onLoad();
    this.props.onLotsLoad(api.Contracts.getLotsUser());
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  processHandler = ( fieldName, file, metadata, load, error ) => {
    console.log('processHandler:fieldName',fieldName);
    console.log('processHandler:file',file);
    console.log('processHandler:metadata',metadata);
    console.log('processHandler:load',load);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    while (this.props.lotId === 0) {
      sleep(2000);
    }

    Promise.resolve(api.Lot.sendFile(this.props.lotId, this.props.inclusaoAlteracao, file)).then(response => {
      this.props.onFileSent();
      load(1);
    })

  };

  serverOptions = {
    process: this.processHandler,
  };

  render() {
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description='Upload de imagens em lote'/>
        <div className="content">
          <div className="row">
            <div className="col-lg-12">
                <div  className="hpanel">
                  <div className="row">
                    <div className="col-md-2">
                      <Field name="inclusaoAlteracao" label="Operação" data={[{ tipo: 'I', desc: 'Inclusão' }, { tipo: 'A', desc: 'Alteração' }]} textField="desc" valueField="tipo" component={SelectField} type="text" />
                    </div>
                  </div>
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12">
                              {this.props.currentUser.funcionalidades.includes(13) &&
                              <FilePond allowMultiple={true}
                                        labelIdle="Arraste os arquivos para upload"
                                        labelFileProcessingComplete="Arquivo enviado"
                                        labelFileLoading='Carregando'
                                        labelFileProcessing='Enviando'
                                        labelTapToCancel=''
                                        allowRevert={false}
                                        allowReplace={false}
                                        files={this.props.files}
                                        acceptedFileTypes={['application/pdf','null']}
                                        labelFileTypeNotAllowed='Tipo de arquivo inválido'
                                        fileValidateTypeLabelExpectedTypes='Esperado um arquivo pdf'
                                        onupdatefiles={ fileItems => {
                                            if (this.props.qttFilesToSend === this.props.sentFiles && fileItems.length > 0) {
                                              console.log('onupdatefiles:',fileItems)
                                              this.props.onFilesToSend([fileItems.length, fileItems]);
                                              Promise.resolve(api.Lot.create()).then(response => {
                                                this.props.onLotCreated(response.idLote);
                                              })
                                            }
                                          }
                                        }
                                        onprocessfile={ (error, file) => {
                                          if (this.props.sentFiles === this.props.qttFilesToSend && this.props.lotId !== 0) {
                                            console.log('TUDO PROCESSADO!!!!!');
                                            Promise.resolve(api.Lot.close(this.props.lotId)).then(response => {
                                              this.props.onLotClosed();
                                              this.props.onLotsLoad(api.Contracts.getLotsUser());
                                              toastr.success('Lote Processado com sucesso');
                                            })
                                          }
                                        }}
                                        server={ this.serverOptions }
                              />
                              }
                            </div>
                        </div>
                        <div className="table-responsive">
                          {this.props.all && this.props.all.length > 0 && this.props.currentUser.funcionalidades.includes(14) &&
                            <ReactTable
                              className="-striped -highlight" defaultPageSize={10}
                              data={this.props.all} loadingText="Carregando..."
                              noDataText="Nenhuma Informação encontrada"
                              showPageSizeOptions={false} minRows={1} showPagination={false}
                              columns={[
                                {
                                  Header: 'DATA DO PROCESSAMENTO',
                                  accessor: 'data',
                                  className: "align-middle text-center",
                                  headerClassName: "font-extra-bold",
                                  Cell: (data) => (<div>{MaskDate.TO_BR(data.original.dataCriacao)}</div>)
                                },{
                                  Header: 'QTD DE ARQUIVOS PROCESSADOS',
                                  accessor: 'rowCount',
                                  className: "align-middle text-center",
                                  headerClassName: "font-extra-bold"
                                  }
                                ]}                              
                              SubComponent={row => {
                                return (
                                  <div style={{ padding: "20px" }}>
                                    <ReactTable
                                      className="-striped -highlight" defaultPageSize={50}
                                      data={row.original.files} loadingText="Carregando..."
                                      noDataText="Nenhuma Informação encontrada"
                                      showPageSizeOptions={false} minRows={1} showPagination={false}
                                      columns={[
                                        {
                                          Header: 'UF',
                                          accessor: 'uf',
                                          className: "align-middle text-center",
                                          headerClassName: "font-extra-bold",
                                          width: 50
                                        },
                                        {
                                          Header: 'NOME DO ARQUIVO',
                                          accessor: 'nomeArquivo',
                                          className: "align-middle text-center",
                                          headerClassName: "font-extra-bold"
                                        },                                        
                                        {
                                          Header: 'MENSAGEM',
                                          accessor: 'mensagem',
                                          className: "align-middle text-center",
                                          headerClassName: "font-extra-bold"
                                        }
                                        ]}
                                    />
                                  </div>
                                );
                              }}
                            />
                          }
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: FILES_UPLOAD_LOAD }),
  onUnload: payload => dispatch({ type: FILES_UPLOAD_UNLOAD }),
  onFilesToSend: payload => dispatch({type: FILES_TO_SEND, payload}),
  onLotCreated: payload => dispatch({type: LOT_CREATED, payload}),
  onFileSent: payload => dispatch({type: FILE_SENT}),
  onLotClosed: payload => dispatch({type: LOT_CLOSED}),
  onLotsLoad: payload => dispatch({type: ALL_LOTE_USER_LOAD, payload}),
  onLotsUnload: payload => dispatch({type: ALL_LOTE_USER_UNLOAD, payload}),
});

const selector = formValueSelector('FilesUploadForm');
const mapStateToProps = state => ({
  currentUser: state.common.currentUser,
  ...state.filesUpload,
  inclusaoAlteracao: selector(state,'inclusaoAlteracao')
});

const form = reduxForm({
  form: 'FilesUploadForm',
});

export default connect(mapStateToProps,mapDispatchToProps)(form(FilesUpload));
