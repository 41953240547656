import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { SubmissionError } from "redux-form";
import { Link, browserHistory } from "react-router";
import { toastr } from "react-redux-toastr";

import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";

import Title from "../../components/Layout/Title";
import api from "../../api/apiUtil";
import { PROFILE_LOAD, PROFILE_UNLOAD, LOGOUT } from "../../actions/Constants";

import _ from "lodash";
import Cookies from "js-cookie";
import uuid from "uuid";
import base64url from "base64url";
import TextIconField from "../../components/Fields/TextIconField";

function publicKeyCredentialToJSON(pubKeyCred) {
  const enc = new TextEncoder();
  if (pubKeyCred instanceof ArrayBuffer) {
    return Uint8ArrayToB64String(pubKeyCred);
  } else if (pubKeyCred instanceof Array) {
    return pubKeyCred.map(publicKeyCredentialToJSON);
  } else if (pubKeyCred instanceof Object) {
    const obj = {};
    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }
    return obj;
  } else return pubKeyCred;
}

function b64stringToUint8Array(b64_string) {
  return Uint8Array.from(atob(b64_string), (c) => c.charCodeAt(0));
}

// takes a uint8 array and returns a b64-encoded string representation of it.
// Maybe a function for this wasn't needed since it does one thing (tm) but it
// looks quirky and I feel better having it tested as a unit.
function Uint8ArrayToB64String(array) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(array)));
}

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allowBiometric: false,
      enabledBiometric: false,
    };
  }

  componentWillMount() {
    if (this.props.currentUser) {
      this.props.onLoad(api.Users.get(this.props.currentUser.id));
    }
    if (navigator.credentials && window.PublicKeyCredential) {
      window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((a) => {
        console.log(a);
        this.setState({ allowBiometric: a, enabledBiometric: Cookies.get("deviceUUID") ? true : false });
      });
    }
  }

  formSubmit(values) {
    var props = this.props;
    var user = {
      id: this.props.currentUser.id,
      cpf: values.cpf.replace(/[^\d]/g, ""),
      email: values.email,
      nome: values.nome,
    };

    Promise.resolve(api.Users.update(user))
      .then((ret) => {
        toastr.success("Sucesso", "Dados alterados");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  formSubmitSenhas(values) {
    var props = this.props;
    var user = {
      id: this.props.currentUser.id,
      password: values.password,
      passwordOperation: values.passwordOperation,
    };

    Promise.resolve(api.Users.update(user))
      .then((ret) => {
        toastr.success("Sucesso", "Senhas alteradas");
      })
      .catch(function (resp) {
        if (resp.message === "Unauthorized" || resp.message === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else if (resp.message === "Unprocessable Entity") {
          toastr.error("Erro", "Algum caractere especial inserido na senha não foi aceito.");
        } else {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        }
      });
  }

  enableBiometric() {
    var deviceUUID = Cookies.get("deviceUUID");
    if (!deviceUUID) {
      deviceUUID = uuid.v4();
      Cookies.set("deviceUUID", deviceUUID, { expires: 365 });
    }
    const userId = `${this.props.currentUser.id}`;
    Promise.resolve(api.Profile.getChallenge()).then((ret) => {
      navigator.credentials
        .create({
          publicKey: {
            rp: {
              id: document.domain,
              name: "e-Registro",
            },
            user: {
              id: b64stringToUint8Array(base64url(`${this.props.currentUser.id}`)),
              name: this.props.currentUser.user,
              displayName: this.props.currentUser.nome,
            },
            pubKeyCredParams: [
              { type: "public-key", alg: -7 },
              { type: "public-key", alg: -257 },
            ],
            challenge: b64stringToUint8Array(base64url(ret.challenge)),
            excludeCredentials: [],
            authenticatorSelection: {
              requireResidentKey: false,
              authenticatorAttachment: "platform",
              userVerification: "discouraged",
            },
            timeout: 90000,
          },
        })
        .then((attestation) => {
          console.log(attestation);
          const webAuthnAttestation = publicKeyCredentialToJSON(attestation);
          console.log(webAuthnAttestation);
          Promise.resolve(api.Profile.savePublicKey(webAuthnAttestation))
            .then((ret2) => {
              toastr.success("Sucesso", "Biometria cadastrada neste dispositivo");
              console.log(ret2);
              this.setState({ enabledBiometric: true });
            })
            .catch(function (resp) {
              toastr.error("Erro", "Erro ao cadastrar biometria para este dispositivo.");
            });
        });
    });
  }

  disableBiometric() {
    Cookies.remove("deviceUUID", { expires: 365 });
    toastr.success("Sucesso", "Biometria removida neste dispositivo");
    this.setState({ enabledBiometric: false });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Altere as informações do seu perfil" />{" "}
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Usuário</div>
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <Field name="nome" label="Nome" component={TextField} type="text" description="Seu nome completo" validate={[FieldValidation.required]} required={true} />

                    <Field name="cpf" label="CPF" component={TextField} type="text" description="Seu CPF completo" normalize={FieldNormalize.CPF} required={true} disabled={true} />

                    <Field name="email" label="Email" component={TextField} type="text" validate={[FieldValidation.required, FieldValidation.email]} required={true} />

                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" to="/secure">
                            {" "}
                            Cancelar
                          </Link>
                          <button className="btn btn-info"> Salvar</button>
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Senhas</div>
                <div className="panel-body">
                  <form className="form-horizontal">
                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          name="password"
                          label="Nova senha de login"
                          component={TextIconField}
                          type="password"
                          description="Sua senha secreta"
                          validate={[FieldValidation.passwordValid]}
                          required={true}
                          describePassword={true}
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="confirmPassword"
                          label="Confirmação da nova senha de login"
                          component={TextIconField}
                          type="password"
                          description="Digite novamente sua senha para confirmar"
                          validate={[FieldValidation.passwordValid]}
                          required={true}
                          describePassword={true}
                        />
                      </div>
                      <div className="col-md-12">
                        <h5 className="font-bold">Senha específica para acessar a Plataforma E-Registro.</h5>
                      </div>
                    </div>

                    {_.findIndex(this.props.currentUser.perfis, function (perfil) {
                      return perfil === "DETRAN";
                    }) > -1 ? (
                      <div></div>
                    ) : (
                      <>
                        <hr />
                        <div className="row">
                          <div className="col-md-6">
                            <Field
                              name="passwordOperation"
                              label="Nova senha de operação"
                              component={TextIconField}
                              type="password"
                              description="Sua senha secreta utilizada para confirmar operações"
                              validate={[FieldValidation.passwordValid]}
                              required={true}
                              describePassword={true}
                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="confirmPasswordOperation"
                              label="Confirmação de nova senha de operação"
                              component={TextIconField}
                              type="password"
                              description="Digite novamente sua senha de operação para confirmar"
                              validate={[FieldValidation.passwordValid]}
                              required={true}
                              describePassword={true}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      <h5 className="font-bold">Senha específica para comunicações (envio de informações) aos Detrans.</h5>
                    </div>
                    {/* <div>
                      <h5 className="font-bold">Alias prezando pela maior segurança de seus usuários!</h5>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" to="/secure">
                            {" "}
                            Cancelar
                          </Link>
                          <button
                            className="btn btn-info"
                            type="button"
                            onClick={this.props.handleSubmit((values) => {
                              return new Promise((resolve, reject) => {
                                const errors = _.assign(validateSenhas(values));
                                if (errors && !_.isEmpty(errors)) {
                                  reject(new SubmissionError(errors));
                                } else {
                                  this.formSubmitSenhas(values);
                                  resolve();
                                }
                              });
                            })}
                          >
                            {" "}
                            Alterar Senhas
                          </button>
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-heading">Biometria</div>
                <div className="panel-body">
                  {!this.state.allowBiometric ? (
                    <div>Este dispositivo não suporta autenticação biométrica.</div>
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.enabledBiometric ? (
                          <button className="btn btn-info" onClick={this.disableBiometric.bind(this)}>
                            Descadastrar biometria
                          </button>
                        ) : (
                          <button className="btn btn-info" onClick={this.enableBiometric.bind(this)}>
                            Cadastrar biometria
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (values.confirmPassword) {
    if (values.password != values.confirmPassword) {
      errors.password = "A senha e a confirmação de senha não conferem";
    }
  }

  if (values.confirmPasswordOperation) {
    if (values.passwordOperation != values.confirmPasswordOperation) {
      errors.passwordOperation = "A senha de operação e a confirmação de senha de operação não conferem";
    }
  }

  return errors;
}

function validateSenhas(values) {
  const errors = {};

  if (!values.password || values.password.length === 0 || !values.password.trim()) {
    errors.password = "Campo obrigatório";
  }
  if (!values.confirmPassword || values.confirmPassword.length === 0 || !values.confirmPassword.trim()) {
    errors.confirmPassword = "Campo obrigatório";
  }
  if (!values.passwordOperation || values.passwordOperation.length === 0 || !values.passwordOperation.trim()) {
    errors.passwordOperation = "Campo obrigatório";
  }
  if (!values.confirmPasswordOperation || values.confirmPasswordOperation.length === 0 || !values.confirmPasswordOperation.trim()) {
    errors.confirmPasswordOperation = "Campo obrigatório";
  }
  if (values.confirmPassword) {
    if (values.password != values.confirmPassword) {
      errors.password = "A senha e a confirmação de senha não conferem";
    }
  }

  if (values.confirmPasswordOperation) {
    if (values.passwordOperation != values.confirmPasswordOperation) {
      errors.passwordOperation = "A senha de operação e a confirmação de senha de operação não conferem";
    }
  }

  return errors;
}

const mapStateToProps = (state) => ({
  ...state.profile,
  currentUser: state.common.currentUser,
  initialValues: state.profile.user ? { ...state.profile.user, password: "", confirmPassword: "", passwordOperation: "", confirmPasswordOperation: "" } : null,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: PROFILE_LOAD, payload }),
  onUnLoad: (payload) => dispatch({ type: PROFILE_UNLOAD }),
  onLogout: () => dispatch({ type: LOGOUT }),
});

const form = reduxForm({
  form: "ProfileForm",
  validate,
});

export default connect(mapStateToProps, mapDispatchToProps)(form(Profile));
