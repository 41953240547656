/* */
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const APP_LOADED = "APP_LOADED";
export const REDIRECTED = "REDIRECTED";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const TOKEN_REFRESH = "TOKEN_REFRESH";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const AUTHORIZED_COMPANIES_LOAD = "AUTHORIZED_COMPANIES_LOAD";
export const SERVICE_STATUS_LOAD = "SERVICE_STATUS_LOAD";
export const SERVICE_STATUS_DETRANS_LOAD = "SERVICE_STATUS_DETRANS_LOAD";
export const EVENTS_LIST_LOAD = "EVENTS_LIST_LOAD";

/* UF */
export const UF_LOAD = "UF_LOAD";
export const CONFIG_UF_SYSTEM_LOAD = "CONFIG_UF_SYSTEM_LOAD";
export const CONFIG_UF_SYSTEM_UNLOAD = "CONFIG_UF_SYSTEM_UNLOAD";
export const CONFIG_UF_EMAIL_LOAD = "CONFIG_UF_EMAIL_LOAD";

/* Confirm Operation */
export const CONFIRM_OPERATION_CLOSE = "CONFIRM_OPERATION_CLOSE";
export const CONFIRM_OPERATION_OPEN = "CONFIRM_OPERATION_OPEN";

/* Confirm Operation */
export const VIDEO_CLOSE = "VIDEO_CLOSE";
export const VIDEO_OPEN = "VIDEO_OPEN";

/* Confirm Not Homologate*/
export const CONFIRM_NOT_HOMOLOGATE_CLOSE = "CONFIRM_NOT_HOMOLOGATE_CLOSE";
export const CONFIRM_NOT_HOMOLOGATE_OPEN = "CONFIRM_NOT_HOMOLOGATE_OPEN";

/* Confirm Action */
export const CONFIRM_ACTION_OPEN = "CONFIRM_ACTION_OPEN";
export const CONFIRM_ACTION_CLOSE = "CONFIRM_ACTION_CLOSE";

/* Confirm Change Password */
export const CONFIRM_CHANGE_PASSWORD_CLOSE = "CONFIRM_CHANGE_PASSWORD_CLOSE";
export const CONFIRM_CHANGE_PASSWORD_OPEN = "CONFIRM_CHANGE_PASSWORD_OPEN";

/* Confirm Gerenciar Autenticacao */
export const CONFIRM_GERENCIAR_AUTENTICACAO_OPEN = "CONFIRM_GERENCIAR_AUTENTICACAO_OPEN";
export const CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE = "CONFIRM_GERENCIAR_AUTENTICACAO_CLOSE";

/* Upload Archive Operation */
export const UPLOAD_ARCHIVE_CLOSE = "UPLOAD_ARCHIVE_CLOSE";
export const UPLOAD_ARCHIVE_OPEN = "UPLOAD_ARCHIVE_OPEN";

/* CONTRACT */
export const CONTRACT_EDIT_LOAD = "CONTRACT_EDIT_LOAD";
export const CONTRACT_EDIT_RESET = "CONTRACT_EDIT_RESET";
export const CONTRACT_EDIT_UNLOAD = "CONTRACT_EDIT_UNLOAD";
export const CONTRACT_EDIT_COMPANIES_LOAD = "CONTRACT_EDIT_COMPANIES_LOAD";
export const CONTRACT_EDIT_COMPANY_ERROR = "CONTRACT_EDIT_COMPANY_ERROR";
export const CONTRACT_VIEW_COMPANY_LOAD = "CONTRACT_VIEW_COMPANY_LOAD";
export const CONTRACT_EDIT_CONTRACT_LOAD = "CONTRACT_EDIT_CONTRACT_LOAD";
export const CONTRACT_EDIT_VEHICLE_ADD = "CONTRACT_EDIT_VEHICLE_ADD";
export const CONTRACT_EDIT_VEHICLE_LOAD = "CONTRACT_EDIT_VEHICLE_LOAD";
export const CONTRACT_EDIT_VEHICLE_SELECTED = "CONTRACT_EDIT_VEHICLE_SELECTED";
export const CONTRACT_EDIT_LOG_LOAD = "CONTRACT_EDIT_LOG_LOAD";
export const CONTRACT_EDIT_LAST_LOG_LOAD = "CONTRACT_EDIT_LAST_LOG_LOAD";
export const CONTRACT_COMUNICA_DETRAN = "CONTRACT_COMUNICA_DETRAN";
export const CONTRACT_NOT_REGISTRED_LOAD = "CONTRACT_NOT_REGISTRED_LOAD";
export const CONTRACT_LIST_FILTER_SELECTED = "CONTRACT_LIST_FILTER_SELECTED";
export const CONTRACT_LIST_COMPANIES_LOAD = "CONTRACT_LIST_COMPANIES_LOAD";
export const CONTRACT_NO_IMAGE_LOAD = "CONTRACT_NO_IMAGE_LOAD";
export const CONTRACT_TOTAL_LOAD = "CONTRACT_TOTAL_LOAD";
export const CONTRACT_EDIT_UPLOAD = "CONTRACT_EDIT_UPLOAD";
export const CONTRACT_REGISTERED_LOAD = "CONTRACT_REGISTERED_LOAD";
export const CONTRACT_ADDITIVE_CREATED = "CONTRACT_ADDITIVE_CREATED";
export const CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD = "CONTRACT_EDIT_CONTRACT_VEHICLE_LOAD";
export const CONTRACT_HOMOLOGATED = "CONTRACT_HOMOLOGATED";
export const CONTRACT_NOT_HOMOLOGATED = "CONTRACT_NOT_HOMOLOGATED";
export const CONTRACT_EDIT_ERR_MSG_LOAD = "CONTRACT_EDIT_ERR_MSG_LOAD";
export const CONTRACT_SENT_SUCCESS = "CONTRACT_SENT_SUCCESS";
export const CONTRACT_DELETE_SUCCESS = "CONTRACT_DELETE_SUCCESS";
export const SHOW_VEHICLE_FORM = "SHOW_VEHICLE_FORM";
export const HIDE_VEHICLE_FORM = "HIDE_VEHICLE_FORM";
export const SHOW_AVERBACAO = "SHOW_AVERBACAO";
export const CONTRACT_EDIT_UPLOAD_SUCCESS = "CONTRACT_EDIT_UPLOAD_SUCCESS";
export const CONTRACT_CANCEL_SUCCESS = "CONTRACT_CANCEL_SUCCESS";
export const CONTRACT_SHELVE_SUCCESS = "CONTRACT_SHELVE_SUCCESS";
export const CONTRACT_LOT_LIST_LOAD = "CONTRACT_LOT_LIST_LOAD";
export const CONTRACT_LOT_LIST_UNLOAD = "CONTRACT_LOT_LIST_UNLOAD";
export const CONTRACT_UF_LOAD = "CONTRACT_UF_LOAD";
export const CONTRACT_LIST_UNLOAD = "CONTRACT_LIST_UNLOAD";
export const CONTRACT_CLEAR_VEHICLE = "CONTRACT_CLEAR_VEHICLE";
export const LOTE_RJ_LIST_LOAD = "LOTE_RJ_LIST_LOAD";
export const LOTE_RJ_LIST_UNLOAD = "LOTE_RJ_LIST_UNLOAD";
export const CONTRACT_REGISTER_PR_UNLOAD = "CONTRACT_REGISTER_PR_UNLOAD";
export const CONTRACT_REGISTER_PR_LOAD = "CONTRACT_REGISTER_PR_LOAD";
export const CONTRACT_CANCEL_ALIAS = "CONTRACT_CANCEL_ALIAS";
export const CONTRACT_BAIXA_ALIAS = "CONTRACT_BAIXA_ALIAS";
export const CARGA_BNDES_LIST_UNLOAD = "CARGA_BNDES_LIST_UNLOAD";
export const CARGA_BNDES_LIST_LOAD = "CARGA_BNDES_LIST_LOAD";
export const CARGA_BNDES_LOAD_UFS = "CARGA_BNDES_LOAD_UFS";
export const LOTE_LIST_LOAD = "LOTE_LIST_LOAD";
export const LOTE_LIST_UNLOAD = "LOTE_LIST_UNLOAD";
export const CONTRACT_TOTAIS_LOAD = "CONTRACT_TOTAIS_LOAD";

/* USERS */
export const USERS_LOAD = "USERS_LOAD";
export const USERS_UNLOAD = "USERS_UNLOAD";
export const USERS_LIST_LOAD = "USERS_LIST_LOAD";
export const USERS_USER_SELECT = "USERS_USER_SELECT";
export const USERS_CORRESPONDENTES_LOAD = "USERS_CORRESPONDENTES_LOAD";
export const USERS_ONLINE_LIST_LOAD = "USERS_ONLINE_LIST_LOAD";
export const USER_LIST_LOG_AUDIT_LOAD = "USER_LIST_LOG_AUDIT_LOAD";
export const USER_LIST_LOG_AUDIT_UNLOAD = "USER_LIST_LOG_AUDIT_UNLOAD";
export const USERS_LIST_LOAD_AUDIT_UNLOAD = "USERS_LIST_LOAD_AUDIT_UNLOAD";
export const USERS_LIST_LOAD_AUDIT = "USERS_LIST_LOAD_AUDIT";
export const USER_LOG_AUDIT_LOAD = "USER_LOG_AUDIT_LOAD";
export const USERS_LOAD_UFS = "USERS_LOAD_UFS";
export const USERS_UNLOAD_UFS = "USERS_UNLOAD_UFS";
export const USERS_LIST_FINANCIAL_LOAD = "USERS_LIST_FINANCIAL_LOAD";

/* PROFILE */
export const PROFILE_LOAD = "PROFILE_LOAD";
export const PROFILE_UNLOAD = "PROFILE_UNLOAD";

/* HOME */
export const HOME_CONTRACTS_LOAD = "HOME_CONTRACTS_LOAD";
export const HOME_CONTRACTS_ERRORS_LOAD = "HOME_CONTRACTS_ERRORS_LOAD";
export const HOME_UNLOAD = "HOME_UNLOAD";
export const HOME_IMAGES_STATUS_LOAD = "HOME_IMAGES_STATUS_LOAD";
export const HOME_DASHBOARD_LIST_LOAD = "HOME_DASHBOARD_LIST_LOAD";
export const HOME_CONTRACTS_EXPIRED_LOAD = "HOME_CONTRACTS_EXPIRED_LOAD";
export const HOME_CONTRACTS_EXPIRED_7_LOAD = "HOME_CONTRACTS_EXPIRED_7_LOAD";
export const HOME_CONTRACTS_EXPIRED_15_LOAD = "HOME_CONTRACTS_EXPIRED_15_LOAD";
export const HOME_CONTRACTS_EXPIRED_30_LOAD = "HOME_CONTRACTS_EXPIRED_30_LOAD";
export const HOME_CONTRACTS_NOT_REGISTRED_LOAD = "HOME_CONTRACTS_NOT_REGISTRED_LOAD";
export const HOME_CONTRACTS_REGISTRED_ERROR_LOAD = "HOME_CONTRACTS_REGISTRED_ERROR_LOAD";
export const HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD = "HOME_CONTRACTS_REGISTRED_WITHOUT_IMAGE_LOAD";
export const HOME_CONTRACTS_REGISTRED_LOAD = "HOME_CONTRACTS_REGISTRED_LOAD";
export const HOME_CONTRACTS_CANCEL_LOAD = "HOME_CONTRACTS_CANCEL_LOAD";
export const HOME_DASHBOARD_SELECTED_MONTH_LOAD = "HOME_DASHBOARD_SELECTED_MONTH_LOAD";
export const HOME_DASHBOARD_COMPANIES_ID_LOAD = "HOME_DASHBOARD_COMPANIES_ID_LOAD";
export const HOME_NEWS_LOAD = "HOME_NEWS_LOAD";
export const HOME_MONIT_EXPIRED_LOAD = "HOME_MONIT_EXPIRED_LOAD";
export const HOME_VIEW_NEW = "HOME_VIEW_NEW";
/* INVOICES */
export const INVOICES_LIST_QUANTITIES_LOAD = "INVOICES_LIST_QUANTITIES_LOAD";
export const INVOICES_LIST_UNLOAD = "INVOICES_LIST_UNLOAD";
export const INVOICES_LIST_LOAD = "INVOICES_LIST_LOAD";
export const INVOICES_LIST_COMPANIES_QUANTITY_LOAD = "INVOICES_LIST_COMPANIES_QUANTITY_LOAD";
export const INVOICE_DETAIL_INVOICES_LOAD = "INVOICE_DETAIL_INVOICES_LOAD";
export const INVOICE_DETAIL_CHARGING_LOAD = "INVOICE_DETAIL_CHARGING_LOAD";
export const INVOICE_DETAIL_INVOICES_DIVERGENCY = "INVOICE_DETAIL_INVOICES_DIVERGENCY";
export const INVOICES_LIST_FINANCIAL_LOAD = "INVOICES_LIST_FINANCIAL_LOAD";

/* APROVE */
export const APROVE_COMPANIES_LOAD = "APROVE_COMPANIES_LOAD";
export const APROVE_REGISTERED_LOAD = "APROVE_REGISTERED_LOAD";
export const APROVE_UNLOAD = "APROVE_UNLOAD";

/* FINANCIAL */
export const FINANCIAL_LIST_LOAD = "FINANCIAL_LIST_LOAD";
export const FINANCIAL_LIST_FILTER = "FINANCIAL_LIST_FILTER";
export const FINANCIAL_LIST_UNLOAD = "FINANCIAL_LIST_UNLOAD";
export const FINANCIAL_EDIT_LOAD = "FINANCIAL_EDIT_LOAD";
export const FINANCIAL_EDIT_UNLOAD = "FINANCIAL_EDIT_UNLOAD";
export const FINANCIAL_INVOICE_LIST_LOAD = "FINANCIAL_INVOICE_LIST_LOAD";
export const FINANCIAL_LIST_LOG_LOAD = "FINANCIAL_LIST_LOG_LOAD";
export const USER_LIST_LOG_LOAD = "USER_LIST_LOG_LOAD";
export const FINANCIAL_LIST_VALUES_PERIOD_LOAD = "FINANCIAL_LIST_VALUES_PERIOD_LOAD";
export const FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD = "FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_LOAD";
export const FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD = "FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_7_LOAD";
export const FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD = "FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_15_LOAD";
export const FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD = "FINANCIAL_DASHBOARD_CONTRACTS_EXPIRED_30_LOAD";
export const FINANCIAL_DASHBOARD_COMPANIES_LOAD = "FINANCIAL_DASHBOARD_COMPANIES_LOAD";
export const UF_SYSTEM_LOAD = "UF_SYSTEM_LOAD";
export const STATUS_DETRAN_LOAD = "STATUS_DETRAN_LOAD";
export const STATUS_DETRAN_UNLOAD = "STATUS_DETRAN_UNLOAD";
export const FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO = "FINANCIAL_DASHBOARD_CLEAR_IMAGENS_VISAO";
export const FINANCIAL_PARCEIRO_LOAD = "FINANCIAL_PARCEIRO_LOAD";
export const STATUS_DETRAN_LOAD_AC = "STATUS_DETRAN_LOAD_AC";

export const FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD = "FINANCIAL_DASHBOARD_NOT_REGISTRED_LOAD";
export const FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD = "FINANCIAL_DASHBOARD_REGISTRED_ERROR_LOAD";
export const FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD = "FINANCIAL_DASHBOARD_REGISTRED_WITHOUT_IMAGE_LOAD";
export const FINANCIAL_DASHBOARD_REGISTRED_LOAD = "FINANCIAL_DASHBOARD_REGISTRED_LOAD";
export const FINANCIAL_INVALID = "FINANCIAL_INVALID";
export const FINANCEIRO_LIST_UNLOAD = "FINANCEIRO_LIST_UNLOAD";
export const FINANCEIRO_LIST_LOAD = "FINANCEIRO_LIST_LOAD";
export const INVOICE_ANEXOS_LIST_LOAD = "INVOICE_ANEXOS_LIST_LOAD";
export const FINANCEIRO_PE_LIST_LOAD = "FINANCEIRO_PE_LIST_LOAD";
export const FINANCEIRO_PE_LIST_UNLOAD = "FINANCEIRO_PE_LIST_UNLOAD";
export const FINANCEIRO_FILES_TO_SEND = "FINANCEIRO_FILES_TO_SEND";
export const FINANCEIRO_FILE_SENT = "FINANCEIRO_FILE_SENT";
export const FINANCEIRO_LOT_CREATED = "FINANCEIRO_LOT_CREATED";
export const FINANCEIRO_LOT_CLOSED = "FINANCEIRO_LOT_CLOSED";

export const VALORES_DETRAN_LOAD = "VALORES_DETRAN_LOAD";
export const VALORES_DETRAN_UNLOAD = "VALORES_DETRAN_UNLOAD";

export const ERROS_DETRAN_LOAD = "ERROS_DETRAN_LOAD";
export const ERROS_DETRAN_UNLOAD = "ERROS_DETRAN_UNLOAD";

/* FINANCIAL REPORT PR */
export const FINANCIAL_REPORT_PR_LOAD = "FINANCIAL_REPORT_PR_LOAD";
export const FINANCIAL_REPORT_PR_UNLOAD = "FINANCIAL_REPORT_PR_UNLOAD";
export const FINANCIAL_REPORT_PR_LIST_LOAD = "FINANCIAL_REPORT_PR_LIST_LOAD";
export const FINANCIAL_REPORT_DETAILS_PR_LOAD = "FINANCIAL_REPORT_DETAILS_PR_LOAD";
export const FINANCIAL_REPORT_DETAILS_PR_UNLOAD = "FINANCIAL_REPORT_DETAILS_PR_UNLOAD";

/* FINANCIAL REPORT SC */
export const FINANCIAL_REPORT_SC_LOAD = "FINANCIAL_REPORT_SC_LOAD";
export const FINANCIAL_REPORT_SC_UNLOAD = "FINANCIAL_REPORT_SC_UNLOAD";
export const FINANCIAL_REPORT_SC_LIST_LOAD = "FINANCIAL_REPORT_SC_LIST_LOAD";
export const FINANCIAL_REPORT_DETAILS_SC_LOAD = "FINANCIAL_REPORT_DETAILS_SC_LOAD";
export const FINANCIAL_REPORT_DETAILS_SC_UNLOAD = "FINANCIAL_REPORT_DETAILS_SC_UNLOAD";

/* FINANCIAL REPORT */
export const FINANCIAL_REPORT_LOAD = "FINANCIAL_REPORT_LOAD";
export const FINANCIAL_REPORT_UNLOAD = "FINANCIAL_REPORT_UNLOAD";
export const FINANCIAL_REPORT_LIST_LOAD = "FINANCIAL_REPORT_LIST_LOAD";
export const FINANCIAL_REPORT_DETAILS_LOAD = "FINANCIAL_REPORT_DETAILS_LOAD";
export const FINANCIAL_REPORT_DETAILS_UNLOAD = "FINANCIAL_REPORT_DETAILS_UNLOAD";

/* PAYMENT SLIP MODAL */
export const PAYMENT_SLIP_MODAL_OPEN = "PAYMENT_SLIP_MODAL_OPEN";
export const PAYMENT_SLIP_MODAL_CLOSE = "PAYMENT_SLIP_MODAL_CLOSE";

/* SUPERVISION */
export const SUPERVISION_COMPANIES_LOAD = "SUPERVISION_COMPANIES_LOAD";
export const SUPERVISION_REGISTERED_LOAD = "SUPERVISION_REGISTERED_LOAD";
export const SUPERVISION_UNLOAD = "SUPERVISION_UNLOAD";

/* FILES_UPLOAD */
export const FILES_UPLOAD_LOAD = "FILES_UPLOAD_LOAD";
export const FILES_UPLOAD_UNLOAD = "FILES_UPLOAD_UNLOAD";
export const FILES_TO_SEND = "FILES_TO_SEND";
export const LOT_CREATED = "LOT_CREATED";
export const FILE_SENT = "FILE_SENT";
export const LOT_CLOSED = "LOT_CLOSED";
export const ALL_LOTE_USER_LOAD = "ALL_LOTE_USER_LOAD";
export const ALL_LOTE_USER_UNLOAD = "ALL_LOTE_USER_UNLOAD";

/* MANAGEMENT REPORT */

export const MANAGEMENT_REPORT_LOAD = "MANAGEMENT_REPORT_LOAD";
export const MANAGEMENT_REPORT_UNLOAD = "MANAGEMENT_REPORT_UNLOAD";
export const MANAGEMENT_REPORT_LIST_LOAD = "MANAGEMENT_REPORT_LIST_LOAD";
export const MANAGEMENT_REPORT_PARCEIRO_LOAD = "MANAGEMENT_REPORT_PARCEIRO_LOAD";

/* MANAGEMENT REPORT ERROR */

export const MANAGEMENT_REPORT_ERROR_LOAD = "MANAGEMENT_REPORT_ERROR_LOAD";
export const MANAGEMENT_REPORT_ERROR_UNLOAD = "MANAGEMENT_REPORT_ERROR_UNLOAD";
export const MANAGEMENT_REPORT_ERROR_LIST = "MANAGEMENT_REPORT_ERROR_LIST";

/* DOCUMENT */
export const DOCUMENT_LIST_LOAD = "DOCUMENT_LIST_LOAD";
export const DOCUMENT_LIST_UNLOAD = "DOCUMENT_LIST_UNLOAD";

/* FINANCIAL SHIPPING */
export const FINANCIAL_SHIPPING_LIST_LOAD = "FINANCIAL_SHIPPING_LIST_LOAD";
export const FINANCIAL_SHIPPING_LIST_UNLOAD = "FINANCIAL_SHIPPING_LIST_UNLOAD";

/* FINANCIAL DASHBOARD */
export const FINANCIAL_DASHBOARD_LIST_LOAD = "FINANCIAL_DASHBOARD_LIST_LOAD";
export const FINANCIAL_DASHBOARD_LIST_UNLOAD = "FINANCIAL_DASHBOARD_LIST_UNLOAD";

/* NEWS */
export const NEWS_LIST_LOAD = "NEWS_LIST_LOAD";
export const NEWS_LIST_UNLOAD = "NEWS_LIST_UNLOAD";
export const NEWS_LOAD_UFS = "NEWS_LOAD_UFS";

/* TERMS OF USE*/
export const CONFIRM_TERMS_OF_USE_OPEN = "CONFIRM_TERMS_OF_USE_OPEN";
export const CONFIRM_TERMS_OF_USE_CLOSE = "CONFIRM_TERMS_OF_USE_CLOSE";

/* FINANCIAL PROFILE */
export const FINANCIAL_PROFILE_LIST_LOAD = "FINANCIAL_PROFILE_LIST_LOAD";
export const FINANCIAL_FEATURES_LIST_LOAD = "FINANCIAL_FEATURES_LIST_LOAD";
export const FINANCIAL_PROFILE_LIST_UNLOAD = "FINANCIAL_PROFILE_LIST_UNLOAD";
export const FINANCIAL_FEATURES_LIST_UNLOAD = "FINANCIAL_FEATURES_LIST_UNLOAD";
export const FINANCIAL_FEATURES_LIST_PERFIL_LOAD = "FINANCIAL_FEATURES_LIST_PERFIL_LOAD";
export const FINANCIAL_FEATURES_LIST_PERFIL_UNLOAD = "FINANCIAL_FEATURES_LIST_PERFIL_UNLOAD";
export const FINANCIAL_GROUP_VERIFY_LOAD = "FINANCIAL_GROUP_VERIFY_LOAD";
export const FINANCIAL_GROUP_VERIFY_UNLOAD = "FINANCIAL_GROUP_VERIFY_UNLOAD";
export const FINANCIAL_GROUP_CLICK = "FINANCIAL_GROUP_CLICK";
export const FINANCIAL_GROUP_UNLOAD_CLICK = "FINANCIAL_GROUP_UNLOAD_CLICK";

/* DETRAN */
export const TAXA_DETRAN_LIST_LOAD = "TAXA_DETRAN_LIST_LOAD";
export const TAXA_DETRAN_LIST_UNLOAD = "TAXA_DETRAN_LIST_UNLOAD";
export const TAXA_DETRAN_ALL_COMPANIES_LOAD = "TAXA_DETRAN_ALL_COMPANIES_LOAD";

/* CONFIA */
export const CONTRACT_CONFIA_LOAD = "CONTRACT_CONFIA_LOAD";
export const CONTRACT_CONFIA_UNLOAD = "CONTRACT_CONFIA_UNLOAD";
export const CAD_CONTRACT_CONFIA_LOAD = "CAD_CONTRACT_CONFIA_LOAD";
export const CAD_CONTRACT_CONFIA_UNLOAD = "CAD_CONTRACT_CONFIA_UNLOAD";
export const CAD_CONTRACT_UF_LOAD = "CAD_CONTRACT_UF_LOAD";
export const CAD_CONTRACT_SENT_SUCCESS = "CAD_CONTRACT_SENT_SUCCESS";

/* CONFIGURACAO */
export const CONFIG_LOAD = "CONFIG_LOAD";
export const CONFIG_UNLOAD = "CONFIG_UNLOAD";

/* SUPORTE*/
export const SUPORTE_LOAD = "SUPORTE_LOAD";
export const SUPORTE_UNLOAD = "SUPORTE_UNLOAD";
export const SUPORTE_ALL_COMPANIES_LOAD = "SUPORTE_ALL_COMPANIES_LOAD";
export const SUPORTE_LOAD_UFS = "SUPORTE_LOAD_UFS";

/*FINANCIAL SEARCH USER LIST */
export const SEARCH_USER_LIST_LOAD = "SEARCH_USER_LIST_LOAD";
export const SEARCH_USER_LIST_UNLOAD = "SEARCH_USER_LIST_UNLOAD";
export const SEARCH_USER_LIST_LOAD_PERFIS = "SEARCH_USER_LIST_LOAD_PERFIS";
export const SEARCH_USER_LIST_ALL_COMPANIES_LOAD = "SEARCH_USER_LIST_ALL_COMPANIES_LOAD";

/* DUDA */
export const DUDA_LOAD = "DUDA_LOAD";
export const DUDA_UNLOAD = "DUDA_UNLOAD";
export const DUDA_FILES_TO_SEND = "DUDA_FILES_TO_SEND";
export const DUDA_FILE_SENT = "DUDA_FILE_SENT";
export const DUDA_LOT_CLOSED = "DUDA_LOT_CLOSED";

/*CONSULTA SNG */
export const CONSULTA_RSNG_LOAD = "CONSULTA_RSNG_LOAD";
export const CONSULTA_RSNG_UNLOAD = "CONSULTA_RSNG_UNLOAD";

/* RELATORIO_MA */
export const RELATORIO_MA_LIST_LOAD = "RELATORIO_MA_LOAD";
export const RELATORIO_MA_UNLOAD = "RELATORIO_MA_UNLOAD";
export const FINANCIAL_RELATORIO_MA_LOAD = "FINANCIAL_RELATORIO_MA_LOAD";
export const RELATORIO_DETAILS_MA_LOAD = "RELATORIO_DETAILS_MA_LOAD";
export const RELATORIO_DETAILS_MA_UNLOAD = "RELATORIO_DETAILS_MA_UNLOAD";

/* INTEGRATIONS */
export const INTEGRATION_CERT_LOAD = "INTEGRATION_CERT_LOAD";
export const INTEGRATION_UNLOAD = "INTEGRATION_UNLOAD";

/* */
export const USER_ALIAS_PARCEIRO_LOAD = "USER_ALIAS_PARCEIRO_LOAD";
export const USER_ALIAS_UNLOAD = "USER_ALIAS_UNLOAD";
export const USER_ALIAS_LOAD = "USER_ALIAS_LOAD";
export const USER_ALIAS_ROLES = "USER_ALIAS_ROLES";

export const SPREADSHEET_ALL_COMPANIES_LOAD = "SPREADSHEET_ALL_COMPANIES_LOAD";
export const SPREADSHEET_UNLOAD = "SPREADSHEET_UNLOAD";
export const SPREADSHEET_VISAO_FINANCEIRA_LOAD = "SPREADSHEET_VISAO_FINANCEIRA_LOAD";

export const CONTACT_LIST_LOAD = "CONTACT_LIST_LOAD";

/* ALIAS PROFILE */
export const ALIAS_PROFILE_LIST_LOAD = "ALIAS_PROFILE_LIST_LOAD";
export const ALIAS_PROFILE_LIST_UNLOAD = "ALIAS_PROFILE_LIST_UNLOAD";
export const ALIAS_FEATURES_LIST_LOAD = "ALIAS_FEATURES_LIST_LOAD";
export const ALIAS_FEATURES_LIST_UNLOAD = "ALIAS_FEATURES_LIST_UNLOAD";
export const ALIAS_GROUP_VERIFY_LOAD = "ALIAS_GROUP_VERIFY_LOAD";
export const ALIAS_GROUP_VERIFY_UNLOAD = "ALIAS_GROUP_VERIFY_UNLOAD";
export const ALIAS_FEATURES_LIST_PERFIL_LOAD = "ALIAS_FEATURES_LIST_PERFIL_LOAD";
export const ALIAS_FEATURES_LIST_PERFIL_UNLOAD = "ALIAS_FEATURES_LIST_PERFIL_UNLOAD";
export const ALIAS_GROUP_CLICK = "ALIAS_GROUP_CLICK";
export const ALIAS_GROUP_UNLOAD_CLICK = "ALIAS_GROUP_UNLOAD_CLICK";
