import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, change, formValueSelector } from "redux-form";
import _ from "lodash";
import { Link, browserHistory } from "react-router";
import Title from "../../components/Layout/Title";

import TextField from "../../components/Fields/TextField";
import FieldNormalize from "../../components/Normalize/FieldNormalize";
import FieldValidation from "../../components/Validation/FieldValidation";
import SelectField from "../../components/Fields/SelectField";
import DropdownListField from "../../components/Fields/DropdownListField";
import api from "../../api/apiUtil";
import MaskCPF from "../../components/Util/MaskCPF";
import { toastr } from "react-redux-toastr";
import { BeatLoader } from "react-spinners";
import SvgIcon from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";

import { SEARCH_USER_LIST_LOAD, SEARCH_USER_LIST_UNLOAD, SEARCH_USER_LIST_LOAD_PERFIS, SEARCH_USER_LIST_ALL_COMPANIES_LOAD, UF_SYSTEM_LOAD, CONFIRM_ACTION_OPEN } from "../../actions/Constants";

class SearchUserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuarioExclusao: {},
    };

    document.addEventListener("keyup", this.keyDownHandler);
  }

  keyDownHandler = (event) => {
    if (event.keyCode === 13 && document.getElementById("filterUsers")) {
      document.getElementById("filterUsers").click();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedfinanceira && !nextProps.selectedfinanceira) {
      this.props.onUfSystemLoad(api.Ufs.sigla_system());
      this.props.onLoadPerfis(api.Roles.all());
      this.props.dispatch(change("SearchUserListForm", "uf", null));
      this.props.dispatch(change("SearchUserListForm", "uf", null));
    }
  }

  componentDidMount() {
    this.props.onUfSystemLoad(api.Ufs.sigla_system());
    this.props.onLoadPerfis(api.Roles.all());
    this.props.onCompaniesLoad(api.Financial.allFinancial());
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.keyDownHandler);
    this.props.onSearchUserListUnload();
  }

  async formSubmit(values) {
    let props = this.props;
    this.setState({ pesquisando: true });
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => {
        return <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />;
      },
    });
    let dados = {
      nome: values.nome,
      cpf: values.cpf ? values.cpf.replaceAll(".", "").replace("-", "") : null,
      uf: values.uf,
      perfis: values.roles,
      financeira: values.financeira && values.financeira.id ? values.financeira.id : values.financeira,
      email: values.email,
      telefone: values.telefone,
      tipoAcesso: values.tipoAcesso,
    };
    Promise.resolve(api.Users.filterSearchUserList(dados))
      .then((res) => {
        this.props.onSearchUserListLoad(res);
        this.setState({ pesquisando: false });
        toastr.removeByType("info");
        if (!this.props.all || this.props.all.length == 0) {
          toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
        }
      })
      .catch(
        function (resp) {
          this.setState({ pesquisando: false });
          toastr.removeByType("info");
          if (resp === "Unauthorized" || resp === "jwt expired") {
            api.Auth.logout();
            props.onLogout();
          } else {
            toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
          }
        }.bind(this)
      );
  }

  preparaUFs(event) {
    this.props.dispatch(change("SearchUserListForm", "uf", null));
    this.props.dispatch(change("SearchUserListForm", "roles", null));
    Promise.resolve(api.Financial.ufsCadastradas(event.id)).then((all) => {
      const newArrayOfObj = all.map(({ uf: sigla, ...rest }) => ({
        sigla,
        ...rest,
      }));
      this.props.onUfSystemLoad(newArrayOfObj);
    });
    this.props.onLoadPerfis(api.Roles.financialRoles(event.id));
  }

  exportToXLSX = () => {
    var DataExcel = [];
    const headersExcell = [["NOME", "CPF", "EMAIL", "TELEFONE", "SITUAÇÃO", "FINANCEIRA", "TIPO ACESSO"]];

    var fileName = "";

    fileName = "Lista de Usuários.";

    DataExcel = this.props.all.map((row) => {
      return {
        nome: row.nome,
        cpf: MaskCPF.TO_CPF(row.cpf),
        email: row.email,
        telefone: row.telefone,
        situacao: row.blocked ? "Bloqueado" : "Ativo",
        financeira: row.razaoSocial,
        tipoAcesso: row.tipoAcesso ? row.tipoAcesso : "Financeira",
      };
    });

    var ws = XLSX.utils.aoa_to_sheet(headersExcell);
    XLSX.utils.sheet_add_json(ws, DataExcel, {
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ListaDeUsuarios");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `ListaDeUsuarios.xlsx`);
  };

  onActionUpdateStatus() {
    this.props.onActionOpen({
      description: `Confirma a exclusão do usuário?`,
      onSuccess: this.handleOnUpdateDelete.bind(this),
    });
  }

  handleOnUpdateDelete() {
    var props = this.props;
    Promise.resolve(api.Users.updateStatus(this.state.usuarioExclusao.id, 1))
      .then((resp) => {
        this.setState({ usuarioExclusao: {} });
        document.getElementById("filterUsers").click();
        toastr.success("Sucesso", "Usuário excluído");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.response.body.message);
        }
      });
  }

  onActionDeleteOpen() {
    this.props.onActionOpen({
      description: `Confirma a exclusão do usuário?`,
      onSuccess: this.handleOnDelete.bind(this),
    });
  }

  handleOnDelete() {
    var props = this.props;
    Promise.resolve(api.Users.deleteUser(this.state.usuarioExclusao.id))
      .then((resp) => {
        this.setState({ usuarioExclusao: {} });
        document.getElementById("filterUsers").click();
        toastr.success("Sucesso", "Usuário excluído");
      })
      .catch(function (resp) {
        if (resp === "Unauthorized" || resp === "jwt expired") {
          api.Auth.logout();
          props.onLogout();
        } else {
          toastr.error("Erro", resp.response.body.message);
        }
      });
  }

  handleOnBlockClick = (id) => {
    Promise.resolve(api.Users.block(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário bloqueado");
      document.getElementById("filterUsers").click();
    });
  };

  handleOnUnblockClick = (id) => {
    Promise.resolve(api.Users.unblock(id)).then((auth) => {
      toastr.success("Sucesso", "Usuário desbloqueado");
      document.getElementById("filterUsers").click();
    });
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <Title routes={this.props.routes} params={this.props.params} description="Consultar Usuários de Financeiras" />
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="hpanel">
                <div className="panel-body">
                  <form className="form-horizontal" onSubmit={handleSubmit(this.formSubmit.bind(this))}>
                    <div className="row">
                      <div className="col-md-3">
                        <Field name="nome" label="Nome" component={TextField} type="text" validate={[FieldValidation.nospace]} />
                      </div>
                      <div className="col-md-2">
                        <Field name="cpf" label="CPF" component={TextField} type="text" normalize={FieldNormalize.CPF} validate={[FieldValidation.cpf]} />
                      </div>
                      <div className="col-md-1">
                        <Field name="uf" label="UF" data={this.props.ufSystem} textField="sigla" valueField="sigla" component={SelectField} type="text" blank={true} />
                      </div>
                      <div className="col-md-2">
                        <Field name="roles" label="Perfil" data={this.props.roles} component={SelectField} type="text" textField="nome" valueField="id" blank={true} />
                      </div>
                      <div className="col-md-4">
                        <Field
                          name="financeira"
                          label="Financeira"
                          data={this.props.allCompanies}
                          credor={true}
                          textField="razaoSocialCnpj"
                          valueField="id"
                          component={DropdownListField}
                          type="text"
                          onChange={this.preparaUFs.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-2">
                        <Field
                          blank={true}
                          name="tipoAcesso"
                          label="Tipo acesso"
                          data={[
                            { id: "NORMAL", name: "Financeira" },
                            { id: "API", name: "API" },
                            { id: "SEND", name: "SEND" },
                          ]}
                          valueField="id"
                          textField="name"
                          component={SelectField}
                          type="text"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field name="email" label="Email" component={TextField} type="text" />
                      </div>
                      <div className="col-md-2">
                        <Field name="telefone" label="Telefone" normalize={FieldNormalize.PHONE} component={TextField} type="text" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-right">
                          <Link className="btn btn-default" onClick={browserHistory.goBack}>
                            <i className="fas fa-arrow-left"></i> Voltar
                          </Link>
                          <Link className="btn btn-default" id="filterUsers" onClick={handleSubmit(this.formSubmit.bind(this))}>
                            {" "}
                            Filtrar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="hpanel">
                      <div className="panel-heading">Usuários cadastrados</div>
                      <div className="panel-body">
                        {this.props.all && this.props.all.length > 0 && (
                          <Link className="btn btn-default float-right" onClick={() => this.exportToXLSX()} title="Gerar Excel">
                            {" "}
                            <SvgIcon size={20} icon={fileExcelO} />
                          </Link>
                        )}
                        <div className="table-responsive">
                          {this.props.all && this.props.all.length > 0 ? (
                            <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th className="text-center align-middle">NOME</th>
                                  <th className="text-center align-middle">CPF</th>
                                  <th className="text-center align-middle">EMAIL</th>
                                  <th className="text-center align-middle">TELEFONE</th>
                                  <th className="text-center align-middle">SITUAÇÃO</th>
                                  <th className="text-center align-middle">FINANCEIRA</th>
                                  <th className="text-center align-middle">TIPO ACESSO</th>
                                  <th className="text-center align-middle">AÇÕES</th>
                                </tr>
                              </thead>
                              <tbody>{this.renderList()}</tbody>
                            </table>
                          ) : (
                            <small>Nenhum registro encontrado</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList() {
    if (!this.props.all) {
      return null;
    }
    return this.props.all.map((user, index) => {
      return (
        <tr key={index}>
          <td className="text-center align-middle">{user.nome}</td>
          <td className="text-center align-middle">{MaskCPF.TO_CPF(user.cpf)}</td>
          <td className="text-center align-middle">{user.email}</td>
          <td className="text-center align-middle">{user.telefone}</td>
          <td className="text-center align-middle">{user.blocked ? "Bloqueado" : "Ativo"}</td>
          <td className="text-center align-middle">{user.razaoSocial}</td>
          <td className="text-center align-middle">{user.tipoAcesso ? user.tipoAcesso : "Financeira"}</td>
          <td className="align-middle text-center">
            <div className="btn-group">
              {this.props.currentUser.funcionalidades.includes(80) && (
                <>
                  {!user.ultimoAcesso ? (
                    <Link className="btn btn-sm btn-default" onClick={() => this.onActionDeleteOpen(this.setState({ usuarioExclusao: { id: user.idUsuario, nome: user.nome } }))}>
                      <i className="fas fa-trash" title="Excluir"></i>
                    </Link>
                  ) : (
                    <Link className="btn btn-sm btn-default" onClick={() => this.onActionUpdateStatus(this.setState({ usuarioExclusao: { id: user.idUsuario, nome: user.nome } }))}>
                      <i className="fas fa-trash" title="Excluir"></i>
                    </Link>
                  )}
                </>
              )}
              {this.props.currentUser.funcionalidades.includes(81) && (
                <>
                  {user.blocked === 0 && (
                    <Link className="btn btn-sm btn-default" onClick={() => this.handleOnBlockClick(user.idUsuario)} title="Bloquear">
                      <i className="fas fa-ban"></i>
                    </Link>
                  )}
                  {user.blocked >= 1 && (
                    <Link className="btn btn-sm btn-default" onClick={() => this.handleOnUnblockClick(user.idUsuario)} title="Ativar">
                      <i className="far fa-check-circle"></i>
                    </Link>
                  )}
                </>
              )}
              {this.props.currentUser.funcionalidades.includes(82) && (
                <Link to={`/alias/financialUser/${user.idFinanceira}/${user.idUsuario}`} className="btn btn-sm btn-default" title="Editar">
                  <i className="far fa-edit" />
                </Link>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPerfis: (payload) => dispatch({ type: SEARCH_USER_LIST_LOAD_PERFIS, payload }),
  onSearchUserListLoad: (payload) => dispatch({ type: SEARCH_USER_LIST_LOAD, payload }),
  onSearchUserListUnload: (payload) => dispatch({ type: SEARCH_USER_LIST_UNLOAD, payload }),
  onCompaniesLoad: (payload) => dispatch({ type: SEARCH_USER_LIST_ALL_COMPANIES_LOAD, payload }),
  onUfSystemLoad: (payload) => dispatch({ type: UF_SYSTEM_LOAD, payload }),
  onActionOpen: (payload) => dispatch({ type: CONFIRM_ACTION_OPEN, payload }),
});

const selector = formValueSelector("SearchUserListForm");
const mapStateToProps = (state) => ({
  ...state.searchUserList,
  selectedfinanceira: selector(state, "financeira"),
  currentUser: state.common.currentUser,
});

const form = reduxForm({
  form: "SearchUserListForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(SearchUserList));
